import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { GrFormEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchData } from "../../Redux/MatchReducer";
import emptyImage from "../../assets/Dashboard/empty-box.png";
import { ThemeContext } from "../../Context/theme";

const Score = () => {
  const navigate = useNavigate();
  const [{ themename }] = React.useContext(ThemeContext);
  const dispatch = useDispatch();
  const game = JSON.parse(localStorage.getItem("referee_module_game"));
  const referee = JSON.parse(localStorage.getItem("referee_module_referee"));
  const data = useSelector((store) => store?.match?.Match?.matches) || [];
  useEffect(() => {
    dispatch(fetchMatchData("ongoing", referee, game));
  }, [dispatch]);
  return (
    <div className={styles.MainContainer}>
      <div className={styles.topContainer}>
        <p className="live-text">Live Scores</p>
      </div>
      <div className={themename ? styles.listDiv_dark : styles.listDiv}>
        {data.length === 0 ? (
          <div className={styles.noData}>
            <img src={emptyImage} alt="" />
            <p>No Live Matches</p>
          </div>
        ) : (
          data.map((el, i) => {
            return (
              <>
                <div
                  className={
                    themename ? styles.carDivUpper_dark : styles.carDivUpper
                  }
                >
                  <div
                    className={
                      themename ? styles.cardDiv2_dark : styles.cardDiv2
                    }
                  >
                    <div className="col-md-5 all-card">
                      <div className={styles.teamDiv}>
                        <img src={el?.team1?.teamId?.profileImg} alt="" />
                        <p className={themename ? "sam-text-dark" : "sam-text"}>
                        {el?.team1?.teamId?.name}
                        </p>
                        <p className={themename ? "sam-text-dark" : "sam-text"}> Player Names :{" "}  
                           {el?.team1?.players[0].name}
                          {el?.team1?.players.length > 1 &&
                             <> ,{el?.team1?.players[1].name}</>}
                        </p>
                        <button className="leargu-img">
                          {el?.stage?.toUpperCase()}
                        </button>
                      </div>
                      <div className="number-card">
                        <h3
                          className={
                            themename ? "number-heading-dark" : "number-heading"
                          }
                        >
                          {el?.score?.points1}
                        </h3>
                        {el.numberOfSets === 3 && (
                          <h4
                            className={
                              themename ? "number-text-dark" : "number-text"
                            }
                          >
                            {el?.score?.team1?.set1
                              ? el?.score?.team1?.set1
                              : "_"}{" "}
                            {el?.score?.team1?.set2
                              ? el?.score?.team1?.set2
                              : "_"}{" "}
                            {el?.score?.team1?.set3
                              ? el?.score?.team1?.set3
                              : "_"}{" "}
                          </h4>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          game === "Pickleball"
                            ? "score-game-card"
                            : "set-game-card"
                        }
                      >
                        {el.numberOfSets === 3 && (
                          <div className="set-card">
                            <p
                              className={
                                themename ? "set-para-dark" : "set-para"
                              }
                            >
                              Set
                            </p>
                            <h1
                              className={
                                themename ? "set-heading-dark" : "set-heading"
                              }
                            >
                              {el?.score?.set}
                            </h1>
                          </div>
                        )}

                        {game === "Pickleball" ? (
                          <div className="set-card"></div>
                        ) : (
                          <div className="set-card">
                            <p
                              className={
                                themename ? "set-para-dark" : "set-para"
                              }
                            >
                              Game
                            </p>
                            <h1
                              className={
                                themename ? "set-heading-dark" : "set-heading"
                              }
                            >
                              {el?.score?.game}
                            </h1>
                          </div>
                        )}
                      </div>
                      <span
                        className={themename ? styles.vs2_dark : styles.vs2}
                      >
                        VS
                      </span>
                      <button
                        className="edit-score-btn"
                        onClick={() =>
                          game === "Pickleball"
                            ? navigate(`/editScore/${el._id}`)
                            : navigate(`/padelScoring/${el._id}`)
                        }
                      >
                        <GrFormEdit fontSize={25} /> Edit Score
                      </button>
                    </div>
                    <div className="col-md-5 all-card">
                      <div className="number-card">
                        <h3
                          className={
                            themename ? "number-heading-dark" : "number-heading"
                          }
                        >
                          {el.score.points2}
                        </h3>
                        {el.numberOfSets === 3 && (
                          <h4
                            className={
                              themename ? "number-text-dark" : "number-text"
                            }
                          >
                            {el?.score?.team2?.set1
                              ? el?.score?.team2?.set1
                              : "_"}{" "}
                            {el?.score?.team2?.set2
                              ? el?.score?.team2?.set2
                              : "_"}{" "}
                            {el?.score?.team2?.set3
                              ? el?.score?.team2?.set3
                              : "_"}{" "}
                          </h4>
                        )}
                      </div>
                      <div className={styles.teamDiv}>
                        <img src={el?.team2?.teamId?.profileImg} alt="" />
                        <p className={themename ? "sam-text-dark" : "sam-text"}>
                          {el?.team2?.teamId?.name}
                        </p>
                        <p className={themename ? "sam-text-dark" : "sam-text"}>Player Names :{" "} 
                          {el?.team2?.players[0].name}
                          {el?.team2?.players.length > 1 &&
                           <> ,{el?.team2?.players[1].name}</>}
                        </p>
                        {/* <img className="leargu-imgs" src={league1} alt="" /> */}
                        <button className="leargu-imgs">
                          {el?.type?.toUpperCase()}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Score;
