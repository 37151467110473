import { createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../requestMethod";

const initialState = {
  Match: [], // Change 'Notification' to 'Match'
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: "Match", // Change 'Notification' to 'Match'
  initialState,
  reducers: {
    getMatchDataRequest: (state) => {
      state.isLoading = true;
    },
    getMatchDataSuccess: (state, action) => {
      state.Match = action.payload; // Change 'Notification' to 'Match'
      state.isLoading = false;
      state.error = null;
    },
    getMatchDataFailure: (state, action) => {
      state.Match = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    createMatchDataRequest: (state) => {
      state.isLoading = true;
    },
    createMatchDataSuccess: (state, action) => {
      state.Match = [];
      state.isLoading = false;
      state.error = null;
    },
    createMatchDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateMatchDataRequest: (state) => {
      state.isLoading = true;
    },
    updateMatchDataSuccess: (state, action) => {
      state.Match = [];
      state.isLoading = false;
      state.error = null;
    },
    updateMatchDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMatchDataRequest,
  getMatchDataSuccess,
  getMatchDataFailure,
  createMatchDataRequest,
  createMatchDataSuccess,
  createMatchDataFailure,
  updateMatchDataRequest,
  updateMatchDataSuccess,
  updateMatchDataFailure,
} = userSlice.actions;

export default userSlice.reducer;

export const fetchMatchData =
  (status = "",referee,game) =>
  async (dispatch) => {
    try {
      dispatch(getMatchDataRequest());
      const response = await userRequest.get(
        `/api/v3/match/getAllMatches?status=${status}&&referee=${referee}&game=${game}`
      );
      dispatch(getMatchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getMatchDataFailure(error.message));
      return error.message;
    }
  };

export const createMatchData = (data) => async (dispatch) => {
  try {
    dispatch(createMatchDataRequest());
     userRequest.post("/api/v3/match/createMatch", data).then((res)=>{
      dispatch(createMatchDataSuccess(res.data));
      return res;
    }).catch((error)=>{
      dispatch(createMatchDataFailure(error.message));
      return error;

    });
    
  } catch (error) {
  
  }
};

export const updateMatchData = (data,id) => async (dispatch) => {
  try {
    dispatch(updateMatchDataRequest());
    const res = await userRequest.put(`/api/v3/match/updateMatch?id=${id}`, data);
    dispatch(updateMatchDataSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(updateMatchDataFailure(error.message));
    return error.message;
  }
};
