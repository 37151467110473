import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
} from '@chakra-ui/react'
import styles from "./styles.module.css"
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'

const Delete = ({deleteTask, id }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

   

    return (
        <>
            <span className={styles.deleteIcon} onClick={onOpen}><MdDelete /></span>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>
                    <div className={styles.deleteModeldiv}>
                        <RiDeleteBin6Fill fontSize={80} color='#DB3B53' />
                        <p>Are your sure want to delete?</p>
                    </div>

                    <ModalFooter>
                        <div className={styles.modelFooter}>
                            <Button variant='solid' color="black" mr={3}
                                onClick={() => deleteTask(id, { deleted: true },onClose)}
                            >
                                Yes
                            </Button>
                            <Button variant='outline' color="black" onClick={onClose}>No</Button>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}

export default Delete