import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import { message } from "antd";
import PlayerCard from "./PlayerCard";

import left from "../../../assets/left.svg";
import { Button } from "@chakra-ui/react";
import { ThemeContext } from "../../../Context/theme";
import EndMatchPadel from "./EndMatchPadel";

const PickleBallScoring = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ themename }] = React.useContext(ThemeContext);
  const [scoreId, setScoreId] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [set, setSet] = useState("");
  const [game, setGame] = useState("");
  const [tie, setTie] = useState("Set Tie Breaker");
  const [scoreArray, setScoreArray] = useState([]);
  const [change, setChange] = useState(false);
  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);
  const [player1, setPlayer1] = useState([]);
  const [player2, setPlayer2] = useState([]);
  const [player3, setPlayer3] = useState([]);
  const [player4, setPlayer4] = useState([]);
  const [btn, setBtn] = useState(false);
  const game2 = JSON.parse(localStorage.getItem("referee_module_game"));

  const getData = (set, game, current) => {
    if (game && set && current) {
      console.log("game:", game);
      userRequest(
        `/api/v3/match/getMatchById?id=${id}&current=${current}&set=${set}&game=${game}`
      )
        .then((res) => {
          setScoreArray(res.data.score);
          setData(res.data.match);
          setScore1(res.data.match.score.points1);
          setScore2(res.data.match.score.points2);

          setScoreId(res.data.match.score._id);
          setBtn(!res.data.match.score.current);
        })
        .catch((err) => {
          console.log("err:", err);
          message.error(err.response.data.error);
        });
    } else {
      userRequest(`/api/v3/match/getMatchById?id=${id}&current=${true}`)
        .then((res) => {
          setScoreArray(res.data.score);
          setData(res.data.match);
          setScore1(res.data.match.score.points1);
          setScore2(res.data.match.score.points2);
          setSet(res.data.match.score.set);
          setGame(res.data.match.score.game);
          setScoreId(res.data.match.score._id);
          setBtn(!res.data.match.score.current);
        })
        .catch((err) => {
          console.log("err:", err);
          message.error(err.response.data.error);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [id, change]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApiCall = (updatedScore1, updatedScore2) => {
    if (scoreId || data?.score?._id) {
      userRequest.put(
        `/api/v3/match/updatePadelScore?id=${scoreId || data?.score?._id}`,
        {
          points1: updatedScore1,
          points2: updatedScore2,
        }
      );
    }
  };

  const changeScore = useCallback(
    (score, team) => {
      if (team === 1) {
        setScore1((prev) => Number(prev) + Number(score));
        debouncedApiCall(Number(score1) + Number(score), score2);
      } else {
        setScore2((prev) => Number(prev) + Number(score));
        debouncedApiCall(Number(score1), Number(score2) + Number(score));
      }

      // Debounce the API call
    },
    [debouncedApiCall, score1, score2]
  );
  const changeScore2 = useCallback(
    (score, team) => {
      if (team === 1) {
        setScore1(score);
        debouncedApiCall(score, score2);
      } else {
        setScore2(score);
        debouncedApiCall(score1, score);
      }

      // Debounce the API call
    },
    [debouncedApiCall]
  );
  const updateScore = async () => {
    try {
      const concatenatedArray = player1.concat(player3, player2, player4);

      userRequest
        .post(`/api/v3/highlight/addScore`, concatenatedArray)
        .then(() => {
          setPlayer1([]);
          setPlayer2([]);
          setPlayer3([]);
          setPlayer4([]);
        });

      message.success("Success");
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong");
    }
  };
  const player1Cards = useMemo(
    () =>
      data?.team1?.players?.length > 0 && (
        <PlayerCard
          scoreArray={scoreArray}
          matchId={id}
          el={data?.team1?.players[0]}
          setSelectedActions={setPlayer1}
          selectedActions={player1}
          getDataMain={setChange}
          change={change}
          teamId={data?.team1?.teamId?._id}
          eventId={data?.eventId._id}
          tournamentId={data?.tournamentId._id}
          set={set}
          game={game}
          tie={tie === "End Match" ? "" : tie}
        />
      ),
    [data?.team1?.players, scoreArray, id, player1, change]
  );

  const player2Cards = useMemo(
    () =>
      data?.team1?.players?.length > 1 && (
        <PlayerCard
          scoreArray={scoreArray}
          matchId={id}
          el={data?.team1?.players[1]}
          setSelectedActions={setPlayer2}
          selectedActions={player2}
          getDataMain={setChange}
          change={change}
          teamId={data?.team1?.teamId?._id}
          eventId={data?.eventId}
          tournamentId={data?.tournamentId}
          set={set}
          game={game}
          tie={tie === "End Match" ? "" : tie}
        />
      ),
    [data?.team1?.players, scoreArray, id, player2, change]
  );

  const player3Cards = useMemo(
    () =>
      data?.team2?.players?.length > 0 && (
        <PlayerCard
          scoreArray={scoreArray}
          matchId={id}
          el={data?.team2?.players[0]}
          setSelectedActions={setPlayer3}
          selectedActions={player3}
          getDataMain={setChange}
          change={change}
          teamId={data?.team2?.teamId?._id}
          eventId={data?.eventId}
          tournamentId={data?.tournamentId}
          set={set}
          game={game}
          tie={tie === "End Match" ? "" : tie}
        />
      ),
    [data?.team2?.players, scoreArray, id, player3, change]
  );

  const player4Cards = useMemo(
    () =>
      data?.team2?.players?.length > 1 && (
        <PlayerCard
          scoreArray={scoreArray}
          matchId={id}
          el={data?.team2?.players[1]}
          setSelectedActions={setPlayer4}
          selectedActions={player4}
          getDataMain={setChange}
          change={change}
          teamId={data?.team2?.teamId?._id}
          eventId={data?.eventId}
          tournamentId={data?.tournamentId}
          set={set}
          game={game}
          tie={tie === "End Match" ? "" : tie}
        />
      ),
    [data?.team2?.players, scoreArray, id, player4, change]
  );

  function declareWinner(team1Points, team2Points, team1Sets, team2Sets) {
    let winner;
    if (team1Points > team2Points) {
      winner = 1;
    } else if (team2Points > team1Points) {
      winner = 2;
    } else {
      winner = 0;
    }

    if (winner === 0) {
      message.warning("cant end set");
      return 0;
    }

    // Update set wins for team1
    if (winner === 1) {
      team1Sets[`set${set}`] = team1Points;
      team2Sets[`set${set}`] = team2Points;
      return {
        team1: team1Sets,
        team2: team2Sets,
      }; // Increment the set win for team1
    }
    if (winner === 2) {
      team1Sets[`set${set}`] = team1Points;
      team2Sets[`set${set}`] = team2Points; // Increment the set win for team2
      return {
        team1: team1Sets,
        team2: team2Sets,
      };
    }
  }
  function determineWinner(scores = data.score) {
    let team1Sets = 0;
    let team2Sets = 0;

    // Loop through each set and compare the scores
    for (let set in scores.team1) {
      if (scores.team1[set] > scores.team2[set]) {
        team1Sets++;
      } else if (scores.team1[set] < scores.team2[set]) {
        team2Sets++;
      }
    }

    // Determine the winner based on sets won
    if (team1Sets > team2Sets) {
      return data?.team1?.teamId?._id;
    } else if (team1Sets < team2Sets) {
      return data?.team2?.teamId?._id;
    } else {
      message.error("It's a tie can't end match now");
      return 0;
    }
  }

  function determineWinner2(scores = data.score) {
    if (score1 > score2) {
      return data?.team1?.teamId?._id;
    } else if (score1 < score2) {
      return data?.team2?.teamId?._id;
    } else {
      message.error("It's a tie can't end match now");
      return 0;
    }

    // Determine the winner based on sets won
  }

  const endGame = () => {
    const winner = declareWinner(
      score1,
      score2,
      data.score.team1,
      data.score.team2
    );

    if (winner) {
      setLoading(true);
      userRequest
        .put(`/api/v3/match/endPadelGame?id=${scoreId || data?.score?._id}`, {
          set: set + 1,

          team1: winner.team1,
          team2: winner.team2,
          matchId: id,
        })
        .then(() => {
          getData();
          setLoading(false);
        });
    }
  };
  const endGameSet = () => {
    if (set === 3) {
      message.error("cant end set at set 3");
      return;
    }
    const winner = declareWinner(
      score1,
      score2,
      data.score.team1,
      data.score.team2
    );

    if (winner) {
      setLoading(true);
      userRequest
        .put(`/api/v3/match/endPadelGame?id=${scoreId || data?.score?._id}`, {
          set: set,

          team1: winner.team1,
          team2: winner.team2,
          matchId: id,
        })
        .then(() => {
          return 1;
        })
        .catch(() => {
          return 0;
        });
    } else {
      return 0;
    }
  };
  const cantEndMatch = () => {
    message.error("cant end set ");
  };

  const finallyEndMatch = (scores = data.score) => {
    const winner = declareWinner(
      score1,
      score2,
      data.score.team1,
      data.score.team2
    );

    const winner2 = determineWinner();
    if (set === 3) {
      if (!!winner && !!winner2) {
        userRequest
          .put(`/api/v3/match/endPadelGame?id=${scoreId || data?.score?._id}`, {
            set: set,

            team1: winner.team1,
            team2: winner.team2,
            matchId: id,
          })
          .then(() => {
            return winner2;
          });
        return winner2;
      } else if (winner2) {
        return winner2;
      }
    } else {
      if (!!winner && !!winner2) {
        userRequest
          .put(`/api/v3/match/endPadelGame?id=${scoreId || data?.score?._id}`, {
            set: set,

            team1: winner.team1,
            team2: winner.team2,
            matchId: id,
          })
          .then(() => {
            return winner2;
          });
        return winner2;
      }
    }
  };
  return (
    <div className={styles.MainContainer}>
      <div className={styles.liveScoresCard}>
        <div className={styles.list}>
          <div className="dropdown-card">
            <img src={left} alt="" onClick={() => navigate("/")} />
            {data.numberOfSets === 3 && (
              <>
                {themename ? (
                  <select
                    name="associationType"
                    class="select_card_dark"
                    value={set}
                    onChange={(e) => {
                      setSet(e.target.value);
                      getData(e.target.value, game, false);
                    }}
                  >
                    <option value="">Select Set</option>
                    <option value="1">Set 1</option>
                    <option value="2">Set 2</option>
                    <option value="3">Set 3</option>
                  </select>
                ) : (
                  <select
                    name="associationType"
                    class="select_card"
                    value={set}
                    onChange={(e) => {
                      setSet(e.target.value);
                      getData(e.target.value, game, false);
                    }}
                  >
                    <option value="">Select Set</option>
                    <option value="1">Set 1</option>
                    <option value="2">Set 2</option>
                    <option value="3">Set 3</option>
                  </select>
                )}
              </>
            )}
          </div>
          <div
            className={themename ? styles.carDivUpper_dark : styles.carDivUpper}
          >
            <div className={styles.upperBtnDiv23}>
              <EndMatchPadel
                btn={btn}
                loading={loading}
                determineWinner={
                  data.numberOfSets === 3 ? finallyEndMatch : determineWinner2
                }
                setLoading={setLoading}
                id={id}
              />
            </div>

            <div className={themename ? styles.cardDiv2_dark : styles.cardDiv2}>
              <div
                className={
                  themename ? "col-md-5 all-card" : "col-md-5 all-card"
                }
              >
                <div className={styles.teamDiv}>
                  <img src={data?.team1?.teamId?.profileImg} alt="" />
                  <p className={themename ? "sam-text-dark" : "sam-text"}>
                    {data?.team1?.teamId?.name}
                  </p>
                  {tie === "Match Tie Breaker" || tie === "Set Tie Breaker" ? (
                    <div className="number-list">
                      <Button
                        disabled={btn}
                        onClick={() => changeScore(1, 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        +1
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore(-1, 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        -1
                      </Button>
                    </div>
                  ) : (
                    <div className="number-list">
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("00", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        00
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("15", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        15
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("30", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        30
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("40", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        40
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("ADV", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        ADV
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("GP", 1)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        GP
                      </Button>
                    </div>
                  )}
                </div>
                <div className="number-card">
                  <h3
                    className={
                      themename ? "number-heading-dark" : "number-heading"
                    }
                  >
                    {score1}
                  </h3>
                  {data.numberOfSets === 3 && (
                    <h4
                      className={themename ? "number-text-dark" : "number-text"}
                    >
                      {data?.score?.team1?.set1
                        ? data?.score?.team1?.set1
                        : "_"}{" "}
                      {data?.score?.team1?.set2
                        ? data?.score?.team1?.set2
                        : "_"}{" "}
                      {data?.score?.team1?.set3
                        ? data?.score?.team1?.set3
                        : "_"}{" "}
                    </h4>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    game2 === "Pickleball" ? "score-game-card" : "set-game-card"
                  }
                >
                  {data.numberOfSets === 3 && (
                    <div className="set-card">
                      <p className={themename ? "set-para-dark" : "set-para"}>
                        Set
                      </p>
                      <h1
                        className={
                          themename ? "set-heading-dark" : "set-heading"
                        }
                      >
                        {set}
                      </h1>
                    </div>
                  )}
                </div>
                <span className={themename ? styles.vs2_dark : styles.vs2}>
                  VS
                </span>

                <div className={styles.endMatchDiv}>
                  {data.numberOfSets === 3 ? (
                    <Button
                      disabled={btn}
                      isLoading={loading}
                      onClick={set < 3 ? endGame : endGameSet}
                      className="edit-score-btn"
                    >
                      End Set
                    </Button>
                  ) : (
                    <Button
                      disabled={btn}
                      isLoading={loading}
                      onClick={cantEndMatch}
                      className="edit-score-btn"
                    >
                      End Set
                    </Button>
                  )}
                </div>
              </div>
              <div className="col-md-5 all-card">
                <div className="number-card">
                  <h3
                    className={
                      themename ? "number-heading-dark" : "number-heading"
                    }
                  >
                    {score2}
                  </h3>
                  {data.numberOfSets === 3 && (
                    <h4
                      className={themename ? "number-text-dark" : "number-text"}
                    >
                      {data?.score?.team2?.set1
                        ? data?.score?.team2?.set1
                        : "_"}{" "}
                      {data?.score?.team2?.set2
                        ? data?.score?.team2?.set2
                        : "_"}{" "}
                      {data?.score?.team2?.set3
                        ? data?.score?.team2?.set3
                        : "_"}{" "}
                    </h4>
                  )}
                </div>
                <div className={styles.teamDiv}>
                  <img src={data?.team2?.teamId?.profileImg} alt="" />
                  <p className={themename ? "sam-text-dark" : "sam-text"}>
                    {data?.team2?.teamId?.name}
                  </p>
                  {tie === "Match Tie Breaker" || tie === "Set Tie Breaker" ? (
                    <div className="number-list">
                      <Button
                        onClick={() => changeScore(1, 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        +1
                      </Button>
                      <Button
                        onClick={() => changeScore(-1, 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        -1
                      </Button>
                    </div>
                  ) : (
                    <div className="number-list">
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("00", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        00
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("15", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        15
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("30", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        30
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("40", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        40
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("ADV", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        ADV
                      </Button>
                      <Button
                        disabled={btn}
                        onClick={() => changeScore2("GP", 2)}
                        className="number-value"
                        _hover={{ bg: "0373EA" }}
                      >
                        GP
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              themename
                ? styles.bottomSectionUpper_dark
                : styles.bottomSectionUpper
            }
          >
            <div
              className={
                themename ? styles.bottomSection_dark : styles.bottomSection
              }
            >
              {data?.tournamentId?.scoreTheme !== "ALGORITHM" && (
                <>
                  <div className={styles.players1}>
                    <div></div>
                    <div>
                      {player1Cards}
                      {player2Cards}
                    </div>
                  </div>
                  <div className={styles.players1}>
                    <div></div>
                    <div>
                      {player3Cards}
                      {player4Cards}
                    </div>
                  </div>
                </>
              )}
            </div>
            {data?.tournamentId?.scoreTheme !== "ALGORITHM" && (
              <div className={styles.bottomBtnDiv}>
                <Button _hover={{ bg: "0373EA" }} onClick={updateScore}>
                  Update Score
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickleBallScoring;
