import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { publicRequest } from "../requestMethod";

const initialState = {
  token: null,
  isLoggedIn: false,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      document.cookie = `token=${action.payload.token}; path=/; SameSite=Strict;`;
      localStorage.setItem(
        "referee_module_tournamentId",
        JSON.stringify(action.payload.user.tournamentId._id)
      );
      localStorage.setItem(
        "referee_module_game",
        JSON.stringify(action.payload.user.game)
      );
      localStorage.setItem(
        "referee_module_referee",
        JSON.stringify(action.payload.user._id)
      );
      localStorage.setItem(
        "referee_module_name",
        JSON.stringify(action.payload.user.name)
      );
    },
    loginFailure: (state, action) => {
      state.token = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = null;
      localStorage.clear();
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout } =
  authSlice.actions;

export default authSlice.reducer;

export const loginUser = (credentials) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    return publicRequest
      .post("/api/v3/authAdmin/loginAdmin", JSON.stringify(credentials))
      .then((res) => {
        if (res.data.user.userType === "REFEREE") {
          dispatch(loginSuccess(res.data));
          message.success("Login Successfully");
          return { status: 200, data: res.data };
        }else{
          message.error("Your not authorized");
        }
      })
      .catch((err) => {
        console.log("err:", err);
        dispatch(loginFailure(err.response.data.message));
        message.error(err.response.data.message);
        throw err.response.data.message;
      });
  } catch (error) {
    dispatch(loginFailure(error.message));
    throw error.message;
  }
};
