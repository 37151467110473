import React, { useState } from "react";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { message } from "antd";
import { loginUser } from "../../../Redux/AuthReducer";
import { ThemeContext } from "../../../Context/theme";
import { color } from "framer-motion";

const Login = () => {
  const dispatch = useDispatch();
  const [{ themename }] = React.useContext(ThemeContext);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (!email && !password) {
      message.error("Please enter email and password ");
    } else {
      if (!email) {
        message.error("Please enter email ");
      } else {
        if (!password) {
          message.error("Please enter Password");
        } else {
          dispatch(loginUser({ email, password }))
            .then((res) => {
              if (res.status === 200) {
                navigate("/");
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log("err:", err);
            });
        }
      }
    }
  };

  const handleClick = () => setShow(!show);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div className={themename?styles.MainContainer_dark :styles.MainContainer}>
      <div className={styles.container}>
        <div>
          <div className={styles.content}>
            <h3>FansPlay - Home Of The Fans</h3>
            <p>
              FansPlay is home to a true immersive experience for the fans to
              engage in the sport they love. Made with keeping the fans in our
              mind FansPlay is a fantasy app that gives the fans an opportunity
              to bring their sports dreams to life.
            </p>
          </div>
        </div>
        <div>
          <div className={styles.formDiv}>
            <p className={themename?styles.inputText_dark :styles.inputText}>Enter your email id</p>
            <InputGroup size="lg">
              <Input
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={(event) => setUsername(event.target.value)}
                focusBorderColor="#B8B8B8"
              />
            </InputGroup>
            <p className={themename?styles.inputText_dark :styles.inputText}>Enter your password</p>
            <InputGroup size="lg" display="flex" alignItems="center">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={handleEnterPress}
                focusBorderColor="#B8B8B8"
              />
              <InputRightElement width="4.5rem" h="70px">
                <p h="1.75rem" onClick={handleClick}>
                  {show ? <AiFillEye color={themename?"#fff":"#000"} /> : <AiFillEyeInvisible color={themename?"#fff":"#000"} />}
                </p>
              </InputRightElement>
            </InputGroup>
            {/* <a className="forget-text" href="">
              Forgot password?
            </a> */}

            <button onClick={() => handleSubmit()}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
