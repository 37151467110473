import React, { useCallback, useState } from "react";
import styles from "./style.module.css";
import LogOut from "../../assets/Home/LogOut.svg";
import Router from "../../Components/Router";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/AuthReducer";
import { Switch } from "@chakra-ui/react";
import { ThemeContext } from "../../Context/theme";
import logo from "../../assets/Home/fansapp.png"

const Home = () => {
  const [{ themename, toggeltheme }] = React.useContext(ThemeContext);
  const [refereeName,setRefereeName]=useState(JSON.parse(localStorage.getItem("referee_module_name")))

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logout());
    window.location.reload();
  }, [dispatch]);
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
  return (
    <>
      <div className={themename ? styles.upperDivDark : styles.upperDiv}>
        <img
          src={themename?logo : "https://pbfantasybuck.s3.us-west-2.amazonaws.com/fansapp.png"}
          alt=""
        />
        <div className={styles.upperDivInner}>
          <span style={{fontSize:"20px",fontWeight:"700"}}>
            Hello, {toTitleCase(refereeName || "")}
          </span>

          <span className={styles.headPhones} onClick={handleLogout}>
            <img src={LogOut} alt="" />
          </span>
          <Switch defaultChecked={themename} onChange={toggeltheme} />
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div>
          <Router />
        </div>
      </div>
    </>
  );
};

export default Home;
