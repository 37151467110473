import React, { useEffect, useState } from 'react';
import styles from "./styles2.module.css"
import { Modal, message } from 'antd';
import { userRequest } from '../../../requestMethod';
import { Avatar } from '@chakra-ui/react';
import Delete from '../../../Components/Delete';

const HighlightModel = ({ score, matchId, playerId, getDataMain }) => {
    const [open, setOpen] = useState(false);
    const [highlight, setHighlight] = useState([])


    const getData = () => {
        userRequest.get(`/api/v3/highlight/getPlayerHighlight?matchId=${matchId}&&playerId=${playerId}`).then((res) => {
            setHighlight(res.data.highlight)

        })
    }
    useEffect(() => {
        getData()
    }, [open])
    const deleteTask = (id, data, onClose) => {
        userRequest.put(`/api/v3/highlight/deleteHighlight?id=${id}`, data).then((res) => {
            getData()
            onClose()
            // window.location.reload()
            getDataMain(prev => !prev)
            setOpen(false)
            message.success("Highlight deleted")

        }).catch((err) => {
            console.log('err:', err)
            message.error("Something went wrong")

        })

    }

    function shortenText(inputText, maxLength) {
        if (inputText.length > maxLength) {
            const shortenedText = inputText.substring(0, maxLength);
            const lastSpaceIndex = shortenedText.lastIndexOf(' ');
            if (lastSpaceIndex !== -1) {
                return shortenedText.substring(0, lastSpaceIndex) + " " + '...';
            } else {
                return shortenedText + " " + '...';
            }
        } else {
            return inputText;
        }
    }
    return (
        <div>
            <p className={styles.playersScore} onClick={() => setOpen(true)}>{score}</p>
            <Modal
                centered
                open={open}
                footer={null}
                onCancel={() => setOpen(false)}
                width={700}


            >
                <p className={styles.topPlayer}>Players</p>
                <div className={styles.table}>
                    <div className={styles.tableHead}>
                        <span>Title</span>
                        <span>Score</span>


                        <span>Action</span>
                    </div>
                    {
                        highlight.map((el, i) => {
                            return (
                                <div className={styles.tableData} key={i}>
                                    <span id={styles.avatar2}>
                                        <div className={styles.avatar}>
                                            <Avatar
                                                w="100%"
                                                h="100%"
                                                name={el.playerId.name}
                                                src={el.playerId.profileImg}
                                            />
                                        </div>

                                        <p className={styles.nameP}> {shortenText(el.title, 35)}</p>
                                    </span>
                                    
                                    <span>{el.points}</span>


                                    <span className={styles.deleteIcon2}>  <Delete deleteTask={deleteTask} id={el._id} /></span>

                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        </div>
    )
}

export default HighlightModel